function GreetingMessage({
  lang,
  info,
  listTeam,
  setInfo,
  status,
  errorGreetingMessage,
  setErrorGreetingMessage,
  errorGreetingMessageBusinessHours,
  setErrorGreetingMessageBusinessHours,
  errorAutoResponseAnswer,
  setErrorAutoResponseAnswer,
  errorAutoResponseAnswerBusinessHours,
  setErrorAutoResponseAnswerBusinessHours,
}) {
  const [greetingMessage, setGreetingMessage] = React.useState("");
  const [autoResponseText, setAutoResponseText] = React.useState("");
  const [isAutoResponseCheck, setIsAutoResponseCheck] = React.useState(false);
  const [isGreetingMessageOn, setIsGreetingMessageOn] = React.useState(false);
  const [autoResponseAnswer, setAutoResponseAnswer] = React.useState(
    status === "business_hours"
      ? info.settingInfo.greeting_message.question.answer
      : info.settingInfo.awayBusinessMessage.greeting_message.question.answer
  );

  let assignList = {
    agents: {},
    teams: {},
  };

  assignList.agents = _.keyBy(info.adminInfo, "nexus_user_id");
  assignList.teams = _.keyBy(listTeam, "team_id");

  React.useEffect(() => {
    setIsGreetingMessageOn(
      status === "business_hours"
        ? info.settingInfo.greeting_message.is_on
        : info.settingInfo.awayBusinessMessage.greeting_message.is_on
    );
    setIsAutoResponseCheck(
      status === "business_hours"
        ? info.settingInfo.greeting_message.question.is_on
        : info.settingInfo.awayBusinessMessage.greeting_message.question.is_on
    );
    setAnswer(
      status === "business_hours"
        ? info.settingInfo.greeting_message.question.answer
        : info.settingInfo.awayBusinessMessage.greeting_message.question.answer
    );
    setGreetingMessage(
      status === "business_hours"
        ? info.settingInfo.greeting_message.message
        : info.settingInfo.awayBusinessMessage.greeting_message.message
    );
    setAutoResponseText(
      status === "business_hours"
        ? info.settingInfo.greeting_message.question.text
        : info.settingInfo.awayBusinessMessage.greeting_message.question.text
    );
  }, [info]);

  const setAnswer = (answer) => {
    let newAutoResponseAnswer = _.cloneDeep(answer);
    if (newAutoResponseAnswer) {
      let countAnswer = 0;
      var addNewItem = true;
      _.forEach(newAutoResponseAnswer, function (item, i) {
        item.id = i;
        if (!item.deleted) {
          if (item.text) {
            countAnswer++;
          }
          newAutoResponseAnswer[i].order = i + 1;
          if (i == newAutoResponseAnswer.length - 1) {
            newAutoResponseAnswer[i].lastItem = true;
            if (!item.text) {
              addNewItem = false;
            }
            if (item.text && item.invalid) {
              answerHasError = true;
            }
          } else {
            newAutoResponseAnswer[i].lastItem = false;
            if (!item.text || item.invalid) {
              answerHasError = true;
            }
          }
        }
        setAssignData(item);
      });
      newAutoResponseAnswer = _.sortBy(newAutoResponseAnswer, "order");
      if (addNewItem && countAnswer < 10) {
        let maxOrder = _.maxBy(newAutoResponseAnswer, "order");
        if (!maxOrder) maxOrder = { order: 0 };

        let answerObject = {
          text: "",
          text_value: "",
          type: "text",
          action: "default",
          response_text: "",
          order: maxOrder.order + 1,
          answerKey: Date.now(),
          assignData: assignList.teams["reception"],
        };

        if(newAutoResponseAnswer.length === 0) {
          answerObject.lastItem = true;
        }
        
        newAutoResponseAnswer.push(answerObject);
        setAutoResponseAnswer(newAutoResponseAnswer);
      }
    }
  };

  const setAssignData = (answer) => {
    if (answer.assign_nexus_user_id) {
      answer.assignData = assignList.agents[answer.assign_nexus_user_id];
    } else if (answer.assign_team_id) {
      answer.assignData =
        assignList.teams[answer.assign_team_id] ||
        assignList.teams["reception"];
    } else {
      answer.assignData = assignList.teams["reception"];
    }
  };

  const updateInfoData = (value, tag) => {
    const newInfo = _.cloneDeep(info);

    if (tag === "GreeTingMessageOn") {
      setIsGreetingMessageOn(value);
      if (status === "business_hours")
        newInfo.settingInfo.greeting_message.is_on = value;
      else
        newInfo.settingInfo.awayBusinessMessage.greeting_message.is_on = value;
    } else if (tag === "AutoResponseCheck") {
      setIsAutoResponseCheck(value);
      if (status === "business_hours")
        newInfo.settingInfo.greeting_message.question.is_on = value;
      else
        newInfo.settingInfo.awayBusinessMessage.greeting_message.question.is_on =
          value;
    } else if (tag === "greetingMessage") {
      setGreetingMessage(value);
      if (status === "business_hours")
        newInfo.settingInfo.greeting_message.message = value;
      else
        newInfo.settingInfo.awayBusinessMessage.greeting_message.message =
          value;
    } else if (tag === "autoResponseText") {
      setAutoResponseText(value);
      if (status === "business_hours")
        newInfo.settingInfo.greeting_message.question.text = value;
      else
        newInfo.settingInfo.awayBusinessMessage.greeting_message.question.text =
          value;
    }

    setInfo(newInfo);
  };

  return (
    <div className="greeting">
      <div
        class="body -scrollable sp_margin"
        ng-form
        name="settingForm"
        ng-hide="isLoading"
      >
        <div class="sp-section">
          <div class="sp-row">
            <div class="sp-col">
              <p class="sp-label">{lang.__setting_greeting_message}</p>
            </div>
            <Toggle defaultStatus={isGreetingMessageOn}>
              <label>
                <input
                  type="checkbox"
                  onChange={() =>
                    updateInfoData(!isGreetingMessageOn, "GreeTingMessageOn")
                  }
                />
                <div class="toggle-button cursor-pointer">
                  <span></span>
                </div>
              </label>
            </Toggle>
          </div>
          <div class="sp-row -form-item">
            <div class="sp-text-help">
              {lang.__setting_greeting_message_description}
            </div>
          </div>
          {isGreetingMessageOn && (
            <>
              <input
                type="text"
                class="sp-input"
                name="autoReplyMessageText"
                style={{
                  border:
                    (errorGreetingMessage && status === "business_hours") ||
                    (errorGreetingMessageBusinessHours &&
                      status === "outside_business_hours")
                      ? "1px solid red"
                      : "",
                  marginBottom: "5px",
                }}
                value={greetingMessage}
                onChange={(e) => {
                  updateInfoData(e.target.value, "greetingMessage");
                  if (
                    errorGreetingMessage ||
                    errorGreetingMessageBusinessHours
                  ) {
                    if (status === "business_hours") {
                      setErrorGreetingMessage(false);
                    } else {
                      setErrorGreetingMessageBusinessHours(false);
                    }
                  }
                }}
                required
              />
              {(errorGreetingMessage && status === "business_hours") ||
              (errorGreetingMessageBusinessHours &&
                status === "outside_business_hours") ? (
                <div
                  style={{
                    color: "red",
                    fontSize: "12px",
                    marginLeft: "5px",
                  }}
                >
                  {lang.__please_fill_out}
                  {lang.__setting_greeting_message}
                </div>
              ) : null}
            </>
          )}
        </div>
        <div class="sp-section">
          <div class="sp-row">
            <div class="sp-col">
              <p class="sp-label">{lang.__greeting_auto_response}</p>
            </div>
            <Toggle defaultStatus={isAutoResponseCheck}>
              <label>
                <input
                  type="checkbox"
                  onChange={() =>
                    updateInfoData(!isAutoResponseCheck, "AutoResponseCheck")
                  }
                />
                <div class="toggle-button cursor-pointer">
                  <span></span>
                </div>
              </label>
            </Toggle>
          </div>
          <div class="sp-row  -form-item">
            <div class="sp-text-help">
              {lang.__greeting_auto_response_description}
            </div>
          </div>
          {isAutoResponseCheck && (
            <div>
              <p class="sp-label">{lang.__greeting_question} *</p>
              <input
                type="text"
                class="sp-input"
                name="autoReplyMessageText"
                style={{
                  border:
                    (errorAutoResponseAnswer && status === "business_hours") ||
                    (errorAutoResponseAnswerBusinessHours &&
                      status === "outside_business_hours")
                      ? "1px solid red"
                      : "",
                  marginBottom: "5px",
                }}
                value={autoResponseText}
                onChange={(e) => {
                  updateInfoData(e.target.value, "autoResponseText");
                  if (
                    errorAutoResponseAnswer ||
                    errorAutoResponseAnswerBusinessHours
                  ) {
                    if (status === "business_hours") {
                      setErrorAutoResponseAnswer(false);
                    } else {
                      setErrorAutoResponseAnswerBusinessHours(false);
                    }
                  }
                }}
                required
              />
              {(errorAutoResponseAnswer && status === "business_hours") ||
              (errorAutoResponseAnswerBusinessHours &&
                status === "outside_business_hours") ? (
                <div
                  style={{
                    color: "red",
                    fontSize: "12px",
                    marginLeft: "5px",
                  }}
                >
                  {lang.__please_fill_out}
                  {lang.__greeting_question}
                </div>
              ) : null}

              <p class="sp-label">{lang.__greeting_answer}</p>

              {autoResponseAnswer.map((answer, index) => {
                return (
                  <AutoResponseAnswer
                    lang={lang}
                    answer={answer}
                    listTeam={listTeam}
                    info={info}
                    setInfo={setInfo}
                    autoResponseAnswer={autoResponseAnswer}
                    setAutoResponseAnswer={setAutoResponseAnswer}
                    assignList={assignList}
                    status={status}
                  />
                );
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
